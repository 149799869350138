import React, { FC } from 'react';
import styled from 'styled-components';
import CheckMark from 'assets/icons/circle-checkmark.svg';
import { Text } from 'components';
import { useRouter } from 'apis/history';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { tablet } from 'styles/breakpoints';
import { useCheckoutData } from 'utils/hooks';

const Container = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  max-width: 26.25rem;
  padding: 2.25rem 1.25rem;
  flex-direction: column;
  align-items: center;
  gap: 1.3125rem;
  border-radius: 0.5rem;
  border: 1px solid #e3e3e3;
  background: #fff;
  @media ${tablet} {
    margin: 0 1rem;
    max-width: unset;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 21.4375rem;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid #c1c4c7;
  @media ${tablet} {
    max-width: unset;
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  margin-left: 0.75rem;
`;

const EditShipping = styled(Text)`
  color: #05a56f;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration: underline;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  max-width: 21.4375rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  @media ${tablet} {
    max-width: unset;
  }
`;

const TextStyled = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
`;

const ShippingInfoWidget: FC = ({ isOpen }) => {
  const { isDiscount } = useCheckoutData();
  const { name, userEmail, phoneNumber, city, street1, postcode, street2 } =
    useSelector((state: AppState) => state.user?.shipping_address);
  return (
    <Container isOpen={isOpen}>
      <ContainerHeader>
        <TitleContainer>
          <CheckMark />
          <Title>Shipping details</Title>
        </TitleContainer>
        <EditShipping
          onClick={() => {
            window.history.replaceState(
              { discount: isDiscount },
              '',
              `${location.pathname.replaceAll(/\/payments/g, '')}${
                location.search
              }`,
            );
          }}
        >
          Edit
        </EditShipping>
      </ContainerHeader>
      <Content>
        {name && <TextStyled>{name}</TextStyled>}
        {userEmail && <TextStyled>{userEmail}</TextStyled>}
        {phoneNumber && <TextStyled>{phoneNumber}</TextStyled>}
        {street1 && street2 && (
          <TextStyled>
            {street1}, {street2}
          </TextStyled>
        )}
        {street1 && !street2 && <TextStyled>{street1}</TextStyled>}
        {city && <TextStyled>{city}</TextStyled>}
        {postcode && <TextStyled>{postcode}</TextStyled>}
      </Content>
    </Container>
  );
};

export default ShippingInfoWidget;
